import React from "react";
import {Link} from "react-router-dom"

//Functional Component 
const MainPage = () => {
  return (
    <div>
      <h3>no page exists</h3>
      <Link to="/">Go Home</Link>
    </div>
  );
};

export default MainPage;